var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "calendar calendario-agendamentos mx-auto",
      class: { "cursor-loading": _vm.loading.events },
    },
    [
      _c("div", { staticClass: "calendar__title mt-8 mb-5" }, [
        _vm._v(" Calendário de agendamentos "),
      ]),
      _vm.now
        ? _c(
            "div",
            {
              staticClass:
                "navigation d-flex align-center justify-space-between mb-2",
            },
            [
              _c("div", { staticClass: "d-flex align-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center px-2 cursor-pointer",
                    on: { click: _vm.getHoje },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M14 11H9V16H14V11ZM13 0V2H5V0H3V2H2C0.895 2 0.00999999 2.895 0.00999999 4L0 18C0 19.105 0.895 20 2 20H16C17.105 20 18 19.105 18 18V4C18 2.895 17.105 2 16 2H15V0H13ZM16 18H2V7H16V18Z",
                            fill: "#4C4C4C",
                          },
                        }),
                      ]
                    ),
                    _c("span", { staticClass: "pl-2 pr-3 cursor-pointer" }, [
                      _vm._v("Hoje"),
                    ]),
                  ]
                ),
                _vm.tipo == "week"
                  ? _c(
                      "div",
                      {
                        class: {
                          navigation__text: _vm.$vuetify.breakpoint.smAndUp,
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.getSemanaCompleta(_vm.now)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.tipo == "week"
                  ? _c("div", { staticClass: "d-flex align-center" }, [
                      _c(
                        "div",
                        {
                          class: {
                            navigation__arrow: _vm.$vuetify.breakpoint.smAndUp,
                            "cursor-pointer": true,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.calendar.prev()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: {
                            navigation__arrow: _vm.$vuetify.breakpoint.smAndUp,
                            "cursor-pointer": true,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.calendar.next()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticStyle: { width: "200px" } },
                [
                  _c("v-select", {
                    ref: "calendarioTipos",
                    staticClass: "ma-2",
                    attrs: {
                      items: _vm.tipos,
                      dense: "",
                      outlined: "",
                      "hide-details": "",
                      label: "",
                      "item-value": "id",
                      "item-text": "nome",
                    },
                    model: {
                      value: _vm.tipo,
                      callback: function ($$v) {
                        _vm.tipo = $$v
                      },
                      expression: "tipo",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-sheet",
        { staticClass: "mx-auto calendar-content", attrs: { height: "400" } },
        [
          _c("v-calendar", {
            ref: "calendar",
            attrs: {
              locale: "pt-br",
              now: _vm.now,
              weekdays: _vm.weekday,
              type: _vm.tipo,
              events: _vm.events,
              "event-overlap-mode": "column",
              "event-overlap-threshold": 30,
              "first-time": "08:00",
              "interval-count": 15,
              "interval-style": _vm.test,
            },
            on: {
              "click:event": _vm.mostrarDetalhes,
              "click:more": _vm.verDia,
              moved: _vm.updateRange,
              change: _vm.getEventos,
            },
            scopedSlots: _vm._u([
              {
                key: "day-header",
                fn: function ({ day, date, present }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "calendar__header text-center",
                        class: { "primary--text": present },
                      },
                      [
                        _c("div", { staticClass: "calendar__weekdate" }, [
                          _vm._v(
                            " " + _vm._s(_vm._f("diaSemanaAbreviado")(date))
                          ),
                        ]),
                        _c("div", { staticClass: "calendar__day" }, [
                          _vm._v(_vm._s(day)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "day-label-header",
                fn: function () {
                  return [_c("span")]
                },
                proxy: true,
              },
              {
                key: "day-body",
                fn: function ({ date, week }) {
                  return [
                    _c("div", {
                      staticClass: "v-current-time",
                      class: { first: date === week[0].date },
                      style: { top: _vm.nowY },
                    }),
                  ]
                },
              },
              {
                key: "interval",
                fn: function ({ weekday }) {
                  return [
                    _c("div", {
                      staticClass: "mx-0",
                      class: `weekday-${
                        weekday == 6 || weekday == 0 ? "weekend" : "workday"
                      }`,
                    }),
                  ]
                },
              },
              {
                key: "event",
                fn: function ({ event }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "event",
                        class: _vm.eventoEstilo(
                          event.tipo,
                          event.contatoRealizado
                        ),
                        attrs: {
                          refs: `event-${event.codigo}`,
                          id: event.codigo,
                        },
                      },
                      [
                        _c("div", { class: `event-${event.tipo}__nome` }, [
                          _vm._v(" " + _vm._s(event.nome) + " "),
                        ]),
                        event.loading
                          ? _c(
                              "div",
                              { staticClass: "event__progress" },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    width: 2,
                                    size: 20,
                                    indeterminate: "",
                                    color: "grey darken-4",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.focus,
              callback: function ($$v) {
                _vm.focus = $$v
              },
              expression: "focus",
            },
          }),
        ],
        1
      ),
      _vm.indicadoEvento
        ? _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                activator: _vm.indicadoEvento,
                "offset-x": "",
              },
              on: { input: _vm.fecharModal },
              model: {
                value: _vm.detalhesModal,
                callback: function ($$v) {
                  _vm.detalhesModal = $$v
                },
                expression: "detalhesModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "calendar__details" },
                [
                  _c("lead-detalhes", {
                    attrs: {
                      item: _vm.indicadoItem,
                      loadingContato: _vm.salvandoContato,
                    },
                    on: {
                      atualizarContato: _vm.atualizarContato,
                      novaProposta: _vm.criarProposta,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "legend my-3" },
        _vm._l(_vm.legendas, function (item, index) {
          return _c(
            "div",
            {
              key: `legenda-${index}`,
              staticClass: "legend__item d-flex align-center mt-2",
              class: { "legend__item--active": _vm.filtros.includes(item.id) },
              on: {
                click: function ($event) {
                  return _vm.mudarFiltro(item.id)
                },
              },
            },
            [
              _c("div", {
                staticClass: "legend__color",
                class: `legend__color--${item.id}`,
              }),
              _c("div", { staticClass: "ml-1 legend__label" }, [
                _vm._v(_vm._s(item.nome)),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }