var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.nome
    ? _c("div", { staticClass: "details pa-3" }, [
        _c("div", { staticClass: "details__title" }, [_vm._v("Indicação")]),
        _c("div", { staticClass: "details__time" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("dataCompleta")(
                  _vm.item.agendamentoTratamento.dataHoraAgendamento
                )
              ) +
              " - " +
              _vm._s(
                _vm._f("horaFormatada")({
                  data: _vm.item.agendamentoTratamento.dataHoraAgendamento,
                })
              ) +
              " às " +
              _vm._s(
                _vm._f("horaFormatada")({
                  data: _vm.item.agendamentoTratamento.dataHoraAgendamento,
                  adicionar: 1,
                })
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "details__item" }, [
          _c("span", { staticClass: "details__label" }, [_vm._v("Nome:")]),
          _vm._v(" " + _vm._s(_vm.item.nome) + " "),
        ]),
        _c("div", { staticClass: "details__item d-flex" }, [
          _c("span", { staticClass: "details__label" }, [_vm._v("Email:")]),
          _c(
            "div",
            _vm._l(_vm.item.emails, function (email, index) {
              return _c("div", { key: `email-${index}` }, [
                _vm._v(_vm._s(email.email)),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "details__item d-flex" }, [
          _c("span", { staticClass: "details__label" }, [_vm._v("Telefone:")]),
          _c(
            "div",
            _vm._l(_vm.item.telefones, function (telefone, index) {
              return _c("div", { key: `telefone-${index}` }, [
                _vm._v(
                  " " +
                    _vm._s(telefone.ddd) +
                    " " +
                    _vm._s(telefone.numero) +
                    " "
                ),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "details__item" }, [
          _c("span", { staticClass: "details__label" }, [_vm._v("Cidade/UF:")]),
          _vm._v(
            " " + _vm._s(_vm.item.cidade) + "/" + _vm._s(_vm.item.estado) + " "
          ),
        ]),
        _c("div", { staticClass: "details__item" }, [
          _c("span", { staticClass: "details__label" }, [_vm._v("Profissão:")]),
          _vm._v(" " + _vm._s(_vm.item.profissao) + " "),
        ]),
        _c("div", { staticClass: "details__item" }, [
          _c("span", { staticClass: "details__label" }, [
            _vm._v("Data de nascimento:"),
          ]),
          _vm._v(
            " " +
              _vm._s(_vm._f("convertDateOutput")(_vm.item.dataNascimento)) +
              " "
          ),
        ]),
        _c("div", { staticClass: "details__item" }, [
          _c("span", { staticClass: "details__label" }, [_vm._v("Operadora:")]),
          _vm._v(" " + _vm._s(_vm.item.operadora) + " "),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "details__form" }, [
          _c("span", { staticClass: "details__label" }, [
            _vm._v("Contato realizado com sucesso?"),
          ]),
          _c(
            "div",
            { staticClass: "mt-n5" },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "details__radio",
                  attrs: { row: "" },
                  model: {
                    value: _vm.contatoRealizado,
                    callback: function ($$v) {
                      _vm.contatoRealizado = $$v
                    },
                    expression: "contatoRealizado",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { label: "Sim", value: true },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "details__radio-label" },
                                [_vm._v("Sim")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3388804286
                    ),
                  }),
                  _c("v-radio", {
                    attrs: { label: "Não", value: false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "details__radio-label" },
                                [_vm._v("Não")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2172641963
                    ),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "details__actions d-flex align-content-stretch",
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "flex-grow-2 mr-4",
                      attrs: {
                        color: "primary",
                        disabled: _vm.loadingContato,
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("novaProposta", _vm.item.idLead)
                        },
                      },
                    },
                    [_vm._v("Criar Proposta")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "flex-grow-1",
                      attrs: { color: "primary", loading: _vm.loadingContato },
                      on: {
                        click: function ($event) {
                          return _vm.atualizarLead(
                            _vm.item.codigo,
                            _vm.contatoRealizado
                          )
                        },
                      },
                    },
                    [_vm._v("salvar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details__item" }, [
      _c("span", { staticClass: "details__label" }, [_vm._v("Observação:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }