<template>
  <div class="calendar calendario-agendamentos mx-auto" :class="{'cursor-loading' : loading.events}">
    <div class="calendar__title mt-8 mb-5">
      Calendário de agendamentos
    </div>
    <div class="navigation d-flex align-center justify-space-between mb-2" v-if="now">
      <div class="d-flex align-center">
        <div class="d-flex align-center px-2 cursor-pointer" @click="getHoje">
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 11H9V16H14V11ZM13 0V2H5V0H3V2H2C0.895 2 0.00999999 2.895 0.00999999 4L0 18C0 19.105 0.895 20 2 20H16C17.105 20 18 19.105 18 18V4C18 2.895 17.105 2 16 2H15V0H13ZM16 18H2V7H16V18Z"
            fill="#4C4C4C"/>
          </svg>
          <span class="pl-2 pr-3 cursor-pointer">Hoje</span>
        </div>
        <div :class="{ navigation__text: $vuetify.breakpoint.smAndUp }" v-if="tipo=='week'">
          {{ getSemanaCompleta(now) }}
        </div>
        <div class="d-flex align-center" v-if="tipo=='week'">
          <div @click="$refs.calendar.prev()" :class="{ navigation__arrow: $vuetify.breakpoint.smAndUp, 'cursor-pointer': true }">
            <v-icon>mdi-chevron-left</v-icon>
          </div>
          <div @click="$refs.calendar.next()" :class="{ navigation__arrow: $vuetify.breakpoint.smAndUp, 'cursor-pointer': true }">
            <v-icon>mdi-chevron-right</v-icon>
          </div>
        </div>
      </div>
      <div style="width: 200px;">
        <v-select
          v-model="tipo"
          :items="tipos"
          dense
          outlined
          hide-details
          class="ma-2"
          label=""
          item-value="id"
          item-text="nome"
          ref="calendarioTipos"
        >
        </v-select>
      </div>
    </div>
    <v-sheet height="400" class="mx-auto calendar-content">
      <v-calendar
        ref="calendar"
        v-model="focus"
        locale="pt-br"
        :now="now"
        :weekdays="weekday"
        :type="tipo"
        :events="events"
        event-overlap-mode="column"
        :event-overlap-threshold="30"
        first-time="08:00"
        :interval-count="15"
        :interval-style="test"
        @click:event="mostrarDetalhes"
        @click:more="verDia"
        @moved="updateRange"
        @change="getEventos"
      >
        <template v-slot:day-header="{ day, date, present }">
          <div class="calendar__header text-center" :class="{'primary--text' : present}">
            <div class="calendar__weekdate"> {{ date | diaSemanaAbreviado }}</div>
            <div class="calendar__day">{{ day }}</div>
          </div>
        </template>
        <template v-slot:day-label-header><span></span></template>
        <template v-slot:day-body="{ date, week }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          ></div>
        </template>
        <template v-slot:interval="{weekday}">
          <div class="mx-0"
          :class="`weekday-${weekday == 6 || weekday == 0 ? 'weekend' : 'workday'}`"></div>
        </template>
        <template v-slot:event="{ event }">
          <div class="event" :refs="`event-${event.codigo}`" :class="eventoEstilo(event.tipo, event.contatoRealizado)" :id="event.codigo">
            <div :class="`event-${event.tipo}__nome`">
              {{ event.nome }}
            </div>
             <div class="event__progress" v-if="event.loading">
                <v-progress-circular
                  :width="2"
                  :size="20"
                  indeterminate
                  color="grey darken-4"
                ></v-progress-circular>
              </div>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
    <v-menu
    v-if="indicadoEvento"
      v-model="detalhesModal"
      :close-on-content-click="false"
      :activator="indicadoEvento"
      offset-x
      @input="fecharModal"
    >
      <div class="calendar__details">
        <lead-detalhes @atualizarContato="atualizarContato" @novaProposta="criarProposta" :item="indicadoItem" :loadingContato="salvandoContato"/>
      </div>
    </v-menu>
    <div class="legend my-3">
      <div
        v-for="(item, index) in legendas"
        :key="`legenda-${index}`"
        class="legend__item d-flex align-center mt-2"
        :class="{'legend__item--active' : filtros.includes(item.id)}"
        @click="mudarFiltro(item.id)"
      >
        <div class="legend__color" :class="`legend__color--${item.id}`"></div>
        <div class="ml-1 legend__label">{{ item.nome }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import leadDetalhes from '@/components/calendario/leadDetalhes.vue';
import { mapActions, mapState } from 'vuex';
import leadService from '@/services/leadService';
import FLPlantaoService from '@/services/focusLeadPlantaoService';
import {
  startOfWeek, format, parseISO, add,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import pt from 'date-fns/locale/pt-BR';

export default {
  data: () => ({
    loading: {
      events: true,
    },
    start: null,
    now: null,
    focus: '',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: true,
    filtros: [],
    tipo: 'week',
    tipos: [
      {
        id: 'week',
        nome: 'semanal',
      },
      {
        id: 'month',
        nome: 'mensal',
      },
      {
        id: 'day',
        nome: 'dia',
      },
    ],
    detalhesModal: false,
    indicadoEvento: null,
    indicadoItem: {},
    ready: false,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: '',
    events: [],
    todosEventos: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    legendas: [
      {
        id: 'plantao',
        nome: 'Plantão',
      },
      {
        id: 'agendamento',
        nome: 'Contato agendado',
      },
      {
        id: 'sucesso',
        nome: 'Contato realizado',
      },
      {
        id: 'insucesso',
        nome: 'Contato não realizado',
      },
    ],
    salvandoContato: false,
  }),
  components: {
    leadDetalhes,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? `${this.cal.timeToY(this.cal.times.now)}px` : '-10px';
    },
  },
  watch: {
    cal(val) {
      if (this.cal) {
        this.scrollToTime();
        this.updateTime();
      }
    },
  },
  mounted() {
    this.getHoje();
    this.ready = true;
    this.scrollToTime();
  },
  methods: {
    ...mapActions({
      setIdLead: 'cadastroProposta/setIdLead',
    }),
    async getEventos() {
      this.loading.events = true;
      this.events = [];
      this.todosEventos = [];
      await this.getLeads();
      await this.buscarPlantoes();
      this.loading.events = false;
    },
    getSemanaCompleta(val) {
      const inicio = startOfWeek(parseISO(val), { weekStartsOn: 1 });
      this.start = format(inicio, 'yyyy-MM-dd');
      const fim = add(inicio, { days: 6 });
      const dif = `${format(fim, 'LLLL') === format(inicio, 'LLLL') ? '' : format(inicio, 'LLLL', { locale: pt })}
      ${format(fim, 'yyyy') === format(inicio, 'yyyy') ? '' : `, ${format(inicio, 'yyyy')}`}
      `;
      return `${format(inicio, 'dd')} ${dif} - ${format(fim, 'dd')}
      ${format(fim, 'LLLL', { locale: pt })}, ${format(fim, 'yyyy')}
      `;
    },
    async getLeads() {
      const dataInicial = new Date();
      const dataFinal = format(add(dataInicial, { days: 6 }), 'yyyy-MM-dd');
      let { lastEnd, lastStart } = this.$refs.calendar;
      lastEnd = lastEnd ? lastEnd.date : dataFinal;
      lastStart = lastStart ? lastStart.date : format(dataInicial, 'yyyy-MM-dd');
      const DataInicioAgendamento = `${lastStart} 00:00:00`;
      const DataFimAgendamento = `${lastEnd} 23:59:59`;
      const codigos = [];
      try {
        const data = await leadService.getListaAgendamentos(this.user.cpf, { DataInicioAgendamento, DataFimAgendamento });

        data.forEach((item) => {
          if (codigos.includes(item.codigo)) return;
          codigos.push(item.codigo);
          let start;
          let end;
          start = item.dataHoraAgendamento;
          end = add(parseISO(item.dataHoraAgendamento), { minutes: 15 });
          start = format(parseISO(start), 'yyyy-MM-dd HH:mm');
          end = format(end, 'yyyy-MM-dd HH:mm');
          const { nome, codigo, contatoRealizado } = item;
          let status = '';
          if (contatoRealizado === null) {
            status = 'agendamento';
          } else {
            status = contatoRealizado ? 'sucesso' : 'insucesso';
            if (contatoRealizado === null) {
              status = 'agendamento';
            } else {
              status = contatoRealizado ? 'sucesso' : 'insucesso';
            }
          }
          this.events.push({
            name: codigo,
            nome,
            codigo,
            start,
            end,
            color: null,
            timed: false,
            tipo: 'agendamento',
            contatoRealizado,
            status,
            loading: false,
          });
          this.todosEventos = this.events;
          this.ready = true;
        });
      } catch (error) {
        console.log(error);
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao buscar leads',
        });
      }
    },
    async buscarPlantoes() {
      try {
        const body = { CPFCorretor: Number(this.user.cpf) };
        const plantoes = await FLPlantaoService.buscarPlantoes(body);
        plantoes.forEach((plantao) => {
          this.events.push({
            nome: 'Plantão',
            start: this.$options.filters.unixToDate(plantao.DataHoraInicio),
            end: this.$options.filters.unixToDate(plantao.DataHoraFim),
            color: null,
            timed: false,
            tipo: 'plantao',
            status: 'plantao',
          });
        });
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao buscar plantões',
        });
      }
    },
    updateRange({ date, start, end }) {
      this.events = [];
      this.now = date;
      // this.$refs.calendar.updateDay();
    },
    getHoje() {
      const date = new Date();
      this.focus = '';
      const timeZone = 'America/Sao_Paulo';
      const zonedDate = utcToZonedTime(date, timeZone);
      this.now = format((zonedDate), 'yyyy-MM-dd HH:mm');
      this.getSemanaCompleta(this.now);
      this.tipo = 'week';
    },
    async mostrarDetalhes({ nativeEvent, event }) {
      nativeEvent.stopPropagation();
      if (event.tipo === 'plantao') return;
      event.loading = true;
      this.indicadoEvento = nativeEvent.target;
      const data = await leadService.getLead({ codigo: event.codigo });
      this.indicadoItem = data;
      this.detalhesModal = true;
      event.loading = false;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    test(interval) {
      return { border: '1px solid #E5E4E3', borderLeft: 0 };
    },
    abrirTipos() {
      // console.log(this.$refs.calendarioTipos);
    },
    getCorEvento(tipo, contato) {
      switch (contato) {
      case null || undefined:
        return tipo;
      case false:
        return 'insucesso';
      case true:
        return 'sucesso';
      default:
        return tipo;
      }
    },
    async atualizarContato(codigo, contatoRealizado) {
      this.salvandoContato = true;
      try {
        await leadService.setContatoRealizado({ codigo, contato: !!contatoRealizado });
        const alterado = this.events.findIndex((evento) => evento.codigo === codigo);
        this.todosEventos[alterado].contatoRealizado = contatoRealizado;
        this.mudarFiltro(null);
        this.$router.push({ name: 'areaLogada.leadCalendarioDetalhe', params: { codigo } });
      } catch (error) {
        // TODO
      } finally {
        this.salvandoContato = false;
      }
    },
    mudarFiltro(id) {
      if (this.filtros.length === 0) {
        this.events = this.todosEventos;
      } else if (!id) {
        this.events = this.todosEventos.filter((event) => this.filtros.includes(event.status));
      } else if (this.filtros.includes(id)) {
        this.filtros = this.filtros.filter((filtro) => filtro !== id);
        this.events = this.todosEventos;
      } else {
        this.filtros.push(id);
        this.events = this.todosEventos.filter((event) => this.filtros.includes(event.status));
      }
    },
    verDia({ date }) {
      this.focus = date;
      this.tipo = 'day';
    },
    eventoEstilo(tipo, contato) {
      const event = this.getCorEvento(tipo, contato);
      const estiloTipo = `event--${tipo} event--${event}`;
      return `${estiloTipo}`;
    },
    criarProposta(codigo) {
      this.setIdLead(codigo);
      this.$router.push({ name: 'areaLogada.criarProposta', query: { idLead: codigo } });
    },
    fecharModal(val) {
      if (!val) {
        this.indicadoItem = {};
        this.detalhesModal = val;
        this.indicadoEvento = null;
      }
    },
  },
  destroy() {
    this.ready = false;
  },
};
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;700&display=swap');
$legends: ("agendamento": #d3d3d3, "sucesso": #80FF91, "insucesso": #FFB2B2, "plantao": #2227CC);
.cursor-loading {
  cursor: wait;
  &.calendar:after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    left: 0;
    top: 0;
  }
}
.v-calendar-daily__intervals-head, .v-calendar-daily__intervals-head:after,
.theme--light.v-calendar-daily .v-calendar-daily__intervals-body {
    background: transparent!important;
    border: 0!important;
}
.calendar-content {
  background: #FFFFFF;
  border: 1px solid #CCCAC9;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0;
}

.calendar {
  font-family: 'Segoe UI';
  width: 95%;
  .calendar__title {
    color: #4C4C4C;
    font-size: 2rem;
    font-weight: bold;
  }
}

.navigation__arrow {
  border: 1.4px solid #4C4C4C;
  text-align: center;
  line-height: 24px;
  border-radius: 4px;
  margin: 0 0.2rem;
}

.navigation__text {
  width: max-content;
  min-width: 260px;
}

.v-calendar-daily_head-day-label {
  display: none!important;
}
.v-current-time {
  border-top: 1px dashed #FF4162;
  position: absolute;
  left: 2px;
  right: 0;
  pointer-events: none;
}

.v-calendar-daily__interval-text:after {
  content: ':00'
}
.event {
  color: #666565;
  font-size: 0.95rem;
  font-weight: 600;
  height: 100%;
  padding: 3px;
  &.event--plantao {
    color: #fff;
  }
  @each $name, $color in $legends {
    &.event--#{$name} {
      background: $color!important;
    }
  }
}
.calendar__details {
  overflow: visible;
  background: #FFFFFF;
  border: 2px solid #807E7E;
  box-sizing: border-box;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  width: 355px;
}
.calendar__header {
  border: 1px solid #E5E4E3;
  border-left: 0;
  border-top: 0;
  color: #807E7E;
  .calendar__weekdate {
    font-size: 0.85rem;
    font-weight: 400;
    padding: 0 0 5px 0;
    text-transform: capitalize;
  }
  .calendar__day {
    font-size: 1rem;
    font-weight: 700;
  }
}

.legend {
  .legend__item {
    border: 1px solid #fff;
    border-radius: 4px;
    cursor: pointer;
    padding: 3px;
    width: 210px;
    &.legend__item--active {
      border-color: rgb(158, 158, 158);
    }
    .legend__color {
      width: 39px;
      height: 24px;
      border-radius: 4px;
      @each $name, $color in $legends {
        &.legend__color--#{$name} {
          background: $color;
        }
      }
    }
  }
}

.event--agendamento {
  position: relative;
}
.event-agendamento__nome {
  position: absolute;
  width: calc(100% - 18px);
  overflow: hidden;
}

.event--agendamento:after{
  content: '...';
  position: absolute;
  right: 2px;
}

.weekday-weekend {
  background: #c4c4c4;
  opacity: 0.2;
  height: 100%;
  width: 100%;
}

.event__progress {
  position: absolute;
  top: 0;
  z-index: 5555555555;
  height: 100%;;
  background: #ffffff40;
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
