<template>
  <div class="details pa-3" v-if="item.nome">
    <div class="details__title">Indicação</div>
    <div class="details__time">
      {{ item.agendamentoTratamento.dataHoraAgendamento | dataCompleta }} -
      {{ ({ data: item.agendamentoTratamento.dataHoraAgendamento }) | horaFormatada }} às
      {{ ({ data: item.agendamentoTratamento.dataHoraAgendamento, adicionar: 1  }) | horaFormatada }}
    </div>
    <div class="details__item">
      <span class="details__label">Nome:</span>
      {{ item.nome }}
    </div>
    <div class="details__item d-flex">
      <span class="details__label">Email:</span>
      <div>
        <div v-for="(email, index) in item.emails" :key="`email-${index}`">{{ email.email }}</div>
      </div>
    </div>
    <div class="details__item d-flex">
      <span class="details__label">Telefone:</span>
      <div>
        <div v-for="(telefone, index) in item.telefones" :key="`telefone-${index}`">
          {{ telefone.ddd }} {{ telefone.numero }}
        </div>
      </div>
    </div>
    <div class="details__item">
      <span class="details__label">Cidade/UF:</span>
      {{ item.cidade }}/{{ item.estado }}
    </div>
    <div class="details__item">
      <span class="details__label">Profissão:</span>
      {{ item.profissao }}
    </div>
    <div class="details__item">
      <span class="details__label">Data de nascimento:</span>
      {{ item.dataNascimento | convertDateOutput }}
    </div>
    <div class="details__item">
      <span class="details__label">Operadora:</span>
      {{ item.operadora }}
    </div>
    <div class="details__item">
      <span class="details__label">Observação:</span>
    </div>
    <div class="details__form">
      <span class="details__label">Contato realizado com sucesso?</span>
      <div class="mt-n5">
        <v-radio-group
          v-model="contatoRealizado"
          row
          class="details__radio"
        >
          <v-radio
            label="Sim"
            :value="true"
          >
            <template v-slot:label>
              <div class="details__radio-label">Sim</div>
            </template>
          </v-radio>
          <v-radio
            label="Não"
            :value="false"
          >
            <template v-slot:label>
              <div class="details__radio-label">Não</div>
            </template>
          </v-radio>
        </v-radio-group>
        <div class="details__actions d-flex align-content-stretch">
          <v-btn class="flex-grow-2 mr-4" color="primary" @click="$emit('novaProposta', item.idLead)" :disabled="loadingContato" outlined>Criar Proposta</v-btn>
          <v-btn class="flex-grow-1" color="primary" :loading="loadingContato" @click="atualizarLead(item.codigo, contatoRealizado)">salvar</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'lead-detalhes',
  props: {
    item: {
      default: {},
    },
    loadingContato: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    contatoRealizado: null,
  }),
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler() {
        if (typeof this.item.contatoRealizado === 'boolean') {
          this.contatoRealizado = this.$cleanObserver(this.item.contatoRealizado);
        } else {
          this.contatoRealizado = '';
        }
      },
    },
  },
  methods: {
    atualizarLead(codigo, contatoRealizado) {
      this.$emit('atualizarContato', codigo, contatoRealizado);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .details__title {
    color: #4C4C4C;
    font-size: 20px;
    font-weight: bold;
    line-height: 2rem;
    &:after {
      content: '';
      display: block;
      background: #000;
      height: 1px;
      width: 100%;
    }
  }
  .details__time {
    color: #1A1A1A;
    font-size: 0.85rem;
    line-height: 1.5rem;
    margin: 8px 0 0 0;
  }
  .details__form, .details__item {
    min-height: 24px;
    line-height: 24px;
    padding: 0 0 0 8px;
    margin: 8px 0 0 0px;
    font-size: 1rem;
  }
  .details__item {
    background: #F4F2F1;
  }
  .details__label {
    font-weight: 500;
    padding: 0 5px 0 0
  }
  .details__form {
    margin: 8px 0 0 0;
  }
  .details__radio-label {
    font-size: 0.85rem;
    line-height: 1px;
  }
}
</style>
